<template>
	<v-dialog
		v-model="show_bid_box"
		height="auto"
		width="1280"
		transition="slide-fade"
		hide-overlay
		light
	>
		<v-row no-gutters dense>
			<v-card min-height="100%" min-width="100%" class="my-card d-flex">
				<v-row no-gutters dense class="d-flex flex-column">
					<v-row no-gutters dense class="d-flex">
						<v-col cols="12">
							<p class="heading-sf20 pb-4">Delivery</p>
						</v-col>

						<v-col cols="12" class="list-header pb-4">
							<v-row no-gutters dense>
								<v-col cols="6" class="d-flex align-center">
									<img
										v-if="delivery_info.category_id === 1"
										:src="$store.state.icons.icons['Lumber']"
										style="height:36px;"
										alt
									/>
									<img
										v-if="delivery_info.category_id === 2"
										:src="$store.state.icons.icons['Electric']"
										style="height:36px;"
										alt
									/>
									<img
										v-if="delivery_info.category_id === 3"
										:src="$store.state.icons.icons['Plumbing']"
										style="height:36px;"
										alt
									/>
									<img
										v-if="delivery_info.category_id === 4"
										:src="$store.state.icons.icons['Masonry']"
										style="height:36px;"
										alt
									/>
									<img
										v-if="delivery_info.category_id === 5"
										:src="$store.state.icons.icons['ToolBoxes']"
										style="height:36px;"
										alt
									/>

									<div class="pl-7">
										<p class="heading-mf15">{{ delivery_info.list_name }}</p>
										<p class="heading-rf13">Delivery ref: {{ delivery_info.delivery_id }}</p>
									</div>
								</v-col>
								<v-col cols="6" class="d-flex align-center justify-end">
									<div
										v-if="this.$store.getters.getDeliveryStatus(delivery_info.delivery_id) === 'IP'"
										class="text-tag-blue text-tag-btn d-flex align-center justify-center my-2"
									>In Process</div>
									<div
										v-else-if="this.$store.getters.getDeliveryStatus(delivery_info.delivery_id) === 'DP'"
										class="text-tag-blue text-tag-btn d-flex align-center justify-center my-2"
									>Dispatched</div>
									<div
										v-else-if="this.$store.getters.getDeliveryStatus(delivery_info.delivery_id) === 'D'"
										class="text-tag-green text-tag-btn d-flex align-center justify-center my-2"
									>Delivered</div>
									<div
										v-else-if="this.$store.getters.getDeliveryStatus(delivery_info.delivery_id) === 'R'"
										class="text-tag-red text-tag-btn d-flex align-center justify-center my-2"
									>Rejected</div>
									<div v-if="this.$store.getters.getDeliveryStatus(delivery_info.delivery_id) === 'DP'" class="d-flex align-center justify-center ml-4">
										<div class="d-flex align-center justify-center mr-4">
											<p class="heading-rf13">Mark as delivered</p>
										</div>
										<div class="d-flex justify-center align-center">
											<div v-on:click="changeStatus()" class="delivery-check-box"></div>
										</div>
									</div>
								</v-col>
								<v-col cols="12" class="d-flex align-center justify-end">
									<div
										v-if="$store.state.auth.error != ''"
										class="p-0 custom-error text-rigth pb-3"
										>{{ this.$store.state.auth.error }}</div>
								</v-col>
							</v-row>
						</v-col>

						<v-col cols="12" class="pt-6">
							<v-row no-gutters dense>
								<v-col cols="12" class="heading-sf16 pb-2">Bid Items</v-col>
								<v-col cols="12" class="pb-4">
									<v-simple-table>
										<template v-slot:default>
											<thead>
												<tr>
													<th class="text-left">Name</th>
													<th class="text-left">Qty</th>
													<th class="text-left">Unit Price</th>
													<th class="text-left">Total</th>
													<th class="text-left">Note</th>
													<th class="text-left">Modification</th>
												</tr>
											</thead>
											<tbody>
												<tr v-for="(item, index) in dataItems" :key="index">
													<td>{{ item.name }}</td>
													<td>{{ item.qty }}</td>
													<td>{{ $options.filters.currency(item.price) }}</td>
													<td>{{ $options.filters.currency(item.qty * item.price) }}</td>
													<td>{{ item.notes !== null ? item.notes : '-' }}</td>
													<td>{{ item.modification === 'Y' ? 'Yes' : 'No' }}</td>
												</tr>
											</tbody>
										</template>
									</v-simple-table>
								</v-col>
							</v-row>
						</v-col>

						<v-col cols="12" class="pt-6">
							<v-row no-gutters dense>
								<v-col cols="12" class="heading-sf16 pb-2">Delivery Information</v-col>
								<v-col cols="6" class="pr-4 pb-4 list-item-view">
									<div class="d-flex pb-2">
										<div class="list-detail-title">Delivery date:</div>
										<div class="heading-rf15">{{ delivery_info.delivery_date }}</div>
									</div>
									<div class="d-flex pb-2">
										<div class="list-detail-title">Delivery time:</div>
										<div class="heading-rf15">{{ delivery_info.delivery_time }}</div>
									</div>
									<div class="d-flex pb-2">
										<div class="list-detail-title">Pickup by contractor:</div>
										<div class="heading-rf15">{{ delivery_info.pickup }}</div>
									</div>
									<div class="d-flex pb-2">
										<div class="list-detail-title">Distance:</div>
										<div class="heading-rf15">{{ delivery_info.distance }}</div>
									</div>
								</v-col>
								<v-col cols="6" class="pl-4 pt-2 pb-4">
									<div class="d-flex pb-2">
										<div class="list-detail-title">Sub Total:</div>
										<div class="heading-rf15">{{ $options.filters.currency(delivery_info.sub_total) }}</div>
									</div>
									<div class="d-flex pb-2">
										<div class="list-detail-title">Shipping Charge:</div>
										<div class="heading-rf15">{{ $options.filters.currency(delivery_info.shipping) }}</div>
									</div>
									<div class="d-flex pb-2">
										<div class="list-detail-title">Service Fee (5%):</div>
										<div class="heading-rf15">{{ $options.filters.currency(delivery_info.service_fee) }}</div>
									</div>
									<div class="d-flex pb-2">
										<div class="list-detail-title">Sales Tax ({{ delivery_info.tax_percentage }}%):</div>
										<div class="heading-rf15">{{ $options.filters.currency(delivery_info.sales_tax) }}</div>
									</div>
									<div class="d-flex pb-2">
										<div class="list-detail-title">Total Amount:</div>
										<div class="heading-rf15">{{ $options.filters.currency(delivery_info.total_amount) }}</div>
									</div>
								</v-col>
							</v-row>
						</v-col>

						<v-col cols="12" class="pt-6">
							<v-row no-gutters dense>
								<v-col cols="6" class="heading-sf16 pb-2">Distributor Information</v-col>
								<v-col cols="6" class="heading-sf16 pb-2 pl-4">Contractor Information</v-col>
								<v-col cols="6" class="pr-4 pb-4 list-item-view">
									<div class="d-flex pb-2">
										<div class="list-detail-title">Company Name:</div>
										<div class="heading-rf15">{{ delivery_info.company_name }}</div>
									</div>
									<div class="d-flex pb-2">
										<div class="list-detail-title">Main Contact:</div>
										<div class="heading-rf15">{{ delivery_info.main_contact }}</div>
									</div>
									<div class="d-flex pb-2">
										<div class="list-detail-title">Contact Number:</div>
										<div class="heading-rf15">{{ delivery_info.company_number }}</div>
									</div>
									<div class="d-flex pb-2">
										<div class="list-detail-title">Address:</div>
										<div class="heading-rf15">{{ delivery_info.company_address }}</div>
									</div>
									<div class="d-flex pb-2">
										<div class="list-detail-title">Distributor Name:</div>
										<div class="heading-rf15">{{ delivery_info.distributor_name }}</div>
									</div>
									<div class="d-flex pb-2">
										<div class="list-detail-title">Contact Number:</div>
										<div class="heading-rf15">{{ delivery_info.distributor_number }}</div>
									</div>
								</v-col>
								<v-col cols="6" class="pl-4 pt-2 pb-4">
									<div class="d-flex pb-2">
										<div class="list-detail-title">Contractor Name:</div>
										<div class="heading-rf15">{{ delivery_info.contractor_name }}</div>
									</div>
									<div class="d-flex pb-2">
										<div class="list-detail-title">Contact Number:</div>
										<div class="heading-rf15">{{ delivery_info.contractor_number }}</div>
									</div>
									<div class="d-flex pb-2">
										<div class="list-detail-title">Address:</div>
										<div class="heading-rf15">{{ delivery_info.address }}</div>
									</div>
									<div class="d-flex pb-2">
										<div class="list-detail-title">List Name:</div>
										<div class="heading-rf15">{{ delivery_info.list_name }}</div>
									</div>
								</v-col>
							</v-row>
						</v-col>
					</v-row>
					
					<v-row no-gutters dense align="stretch" class="d-flex">
						<v-col cols="12" class="d-flex justify-end mt-auto pt-4">
							<v-btn class="my-outline-btn" id="my_elevation" @click="show_bid_box = false" depressed>Close</v-btn>
						</v-col>
					</v-row>
				</v-row>
			</v-card>
		</v-row>
	</v-dialog>
</template>

<script>
import { deliveryDetails, changeDeliveryStatus } from '../../services/deliveryservice';
import { covLocalTime, covLocalDate } from '../../services/commonservice';
export default {
	data() {
		return {
			show_bid_box: false,
			delivery_info: {
				bid_items: []
			}
		};
	},

	computed: {
		dataItems() {
			return this.delivery_info.bid_items;
		},
	},

	methods: {
		async openDeliveryView(id) {
			let data = await deliveryDetails(id);
		
			this.delivery_info.delivery_id = data.data.delivery_id;
			this.delivery_info.status = data.data.status;

			this.delivery_info.list_name = data.data.clist.list_name;
			this.delivery_info.category_id = data.data.clist.category_id;
			this.delivery_info.pickup = data.data.clist.pickup === 'Y' ? 'Yes' : 'No' ;
			this.delivery_info.address = data.data.clist.address;

			this.delivery_info.contractor_name = data.data.contractor.fullname;
			this.delivery_info.contractor_number = data.data.contractor.phone === null 
														? null 
														: data.data.contractor.phone.length > 0 ? data.data.contractor.phone.replace(/(\d{1,3})(\d{1,3})(\d{1,4})/g, "($1)-$2-$3") : '-';

			this.delivery_info.company_name = data.data.distributorcompany.name;
			this.delivery_info.main_contact = data.data.distributorcompany.main_contact;
			this.delivery_info.company_number = data.data.distributorcompany.phone === null 
														? null 
														: data.data.distributorcompany.phone.length > 0 ? data.data.distributorcompany.phone.replace(/(\d{1,3})(\d{1,3})(\d{1,4})/g, "($1)-$2-$3") : '-';
			this.delivery_info.company_address = data.data.distributorcompany.address;
			
			this.delivery_info.distributor_name = data.data.distributor.fullname;
			this.delivery_info.distributor_number = data.data.distributor.phone === null 
														? null 
														: data.data.distributor.phone.length > 0 ? data.data.distributor.phone.replace(/(\d{1,3})(\d{1,3})(\d{1,4})/g, "($1)-$2-$3") : '-';

			this.delivery_info.delivery_date = covLocalDate(data.data.bid.date_of_delivery, data.data.bid.start_time);
			this.delivery_info.delivery_time = covLocalTime(data.data.bid.date_of_delivery, data.data.bid.start_time) + " - " + covLocalTime(data.data.bid.date_of_delivery, data.data.bid.end_time);
			this.delivery_info.distance =  parseFloat(data.data.bid.distance).toFixed(2);
			this.delivery_info.sub_total =  data.data.bid.sub_total;
			this.delivery_info.shipping =  data.data.bid.shipping;
			this.delivery_info.service_fee =  data.data.bid.service_fee;
			this.delivery_info.sales_tax =  data.data.bid.sales_tax;
			this.delivery_info.tax_percentage =  data.data.bid.tax_percentage;
			this.delivery_info.total_amount =  data.data.bid.total_amount;

			this.delivery_info.bid_items = [];

			data.data.bid.biditems.forEach(item => {
				let ob = {
					name: item.name,
					qty: item.qty,
					price: item.price,
					notes: item.notes,
					modification: item.modification
				}

				this.delivery_info.bid_items.push(ob);
			});

			this.show_bid_box = true;
		},

		async changeStatus(){
			if (
				window.confirm(
					"Are you sure this delivery material received by the contractor?"
				)
			) {
				let ob = {
					status: 'D'
				};
				let data = await changeDeliveryStatus(this.delivery_info.delivery_id, ob);
				if (data.data) {
					this.delivery_info.status = "D";
					let ob = {
						delivery_id: this.delivery_info.delivery_id,
                    	company_name: this.delivery_info.company_name,
						date_of_delivery: this.delivery_info.delivery_date,
						time_of_delivery: this.delivery_info.delivery_time,
						distance: this.delivery_info.distance,
						status: "D",
						pickup: this.delivery_info.pickup === "Yes" ? 'Y' : 'N',
						total_amount: this.delivery_info.total_amount,
					}
					this.$store.commit("changeDeliveryStatus", ob);
					this.$store.commit("errorMessage", "");
				}
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.list-header {
	border-bottom: 1px solid #dde2ee;
}

.list-item-view {
	border-right: 1px solid #dde2ee;
}

.list-detail-title {
	font-family: Inter-Regular;
	font-size: 15px;
	color: #7687a2;
	width: 200px;
}
@media (min-width: 1080px) {
	.theme--light.v-data-table table {
		min-width: 100% !important;
	}
}
</style>